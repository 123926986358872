import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { RemoveUnderscorePipe } from './remove-underscore/remove-underscore.pipe';
import { SelectionKeeperPipe } from './selection-keeper/selection-keeper.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { CoordinatePipe } from './coordinate/coordinate.pipe';
import { ExceededPipe } from './exceeded/exceeded.pipe';
import { MathRoundPipe } from './math-round/math-round.pipe';

@NgModule({
  imports: [],
  declarations: [
    SafeHtmlPipe,
    SafeUrlPipe,
    FilterPipe,
    RemoveUnderscorePipe,
    SelectionKeeperPipe,
    CoordinatePipe,
    ExceededPipe,
    MathRoundPipe,
  ],
  exports: [
    SafeHtmlPipe,
    SafeUrlPipe,
    FilterPipe,
    RemoveUnderscorePipe,
    SelectionKeeperPipe,
    CoordinatePipe,
    ExceededPipe,
    MathRoundPipe,
  ],
})
export class PipesModule {}
