import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash';

@Pipe({
  name: 'selectionKeeper',
  pure: true,
})
export class SelectionKeeperPipe implements PipeTransform {
  /**
   * Check if a list includes the value, comparision can be set with keyComparator
   * @param value input value
   * @param selectedItems list of items
   * @param keyComparator comparision key of the object
   */
  transform<T>(value: T, selectedItems: T[], keyComparator: string = 'id'): boolean {
    return !!find(selectedItems, (selectedItem) => selectedItem[keyComparator] === value[keyComparator]);
  }
}
