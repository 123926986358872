import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import moment, { Moment } from 'moment';

@Component({
  selector: 'cube-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        // Year only format
        parse: {
          dateInput: 'YYYY',
        },
        display: {
          dateA11yLabel: 'LL',
          dateInput: 'YYYY',
          monthYearA11yLabel: 'YYYY',
          monthYearLabel: 'YYYY',
        },
      },
    },
  ],
})
export class YearPickerComponent implements OnInit {
  @Input() formControlInput: AbstractControl;

  yearFormControl: UntypedFormControl;
  maxDate: Date;
  minDate: Date;

  constructor() {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(1930, 0, 1);
    this.maxDate = new Date(currentYear, 11, 31);
    this.yearFormControl = new UntypedFormControl(null);
  }

  ngOnInit(): void {
    const selectedDate = moment().year(this.formControlInput.value);
    this.yearFormControl.setValue(selectedDate);
  }

  onClickClearYear(): void {
    this.yearFormControl?.reset(null, { emitEvent: false });
    this.formControlInput.setValue(this.yearFormControl.value);
  }

  onYearSelected(
    normalizedYear: Moment,
    yearPicker: MatDatepicker<Moment>
  ): void {
    const selectedDate = moment(normalizedYear);
    selectedDate.year(normalizedYear.year());
    this.yearFormControl.setValue(selectedDate);
    yearPicker.close();

    this.formControlInput.setValue(selectedDate.year());
  }
}
