import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { ValidationService } from '../../../services/validation/validation.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cube-validation-error',
  template: `<span
    class="error"
    *ngIf="control.invalid && (control.touched || control.dirty)"
    >{{ errorMsgKey | translate : errorMsgParams }}
  </span>`,
  styles: [
    `
      .error {
        color: #ff2c00;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
        font-size: 11px;
        line-height: 1.125;
      }
    `,
  ],
})
export class ValidationErrorComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() field: string;

  errorMsgKey: string = '';
  errorMsgParams: Record<string, any>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private validationService: ValidationService) {}

  ngOnInit(): void {
    this.setEventListeners();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getErrorMsg(): [string, Record<string, any> | null] {
    const errors = this.control.errors;

    for (const errorName in errors) {
      if (errors.hasOwnProperty(errorName)) {
        return this.validationService.getMessage(
          errorName,
          errors[errorName],
          this.field
        );
      }
    }

    return null;
  }

  private setEventListeners(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const errorMsg = this.getErrorMsg();

      if (errorMsg != null) {
        [this.errorMsgKey, this.errorMsgParams] = errorMsg;
      }
    });
  }
}
