import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coordinate',
})
export class CoordinatePipe implements PipeTransform {
  /**
   * convert a decimal value to degree format
   * @param value decimal value
   * @param direction latitude or longitude
   */
  transform(value: number, direction: string): string {
    if (!value) {
      return 'n.a.';
    }

    const dir = value < 0 ? (direction === 'longitude' ? 'W' : 'S') : direction === 'longitude' ? 'E' : 'N';

    const absDd = Math.abs(value);
    // tslint:disable-next-line:no-bitwise
    const deg = absDd | 0;
    const frac = absDd - deg;
    // tslint:disable-next-line:no-bitwise
    const min = (frac * 60) | 0;
    const sec = frac * 3600 - min * 60;

    // Round it to 2 decimal points.
    return (
      deg.toString().padStart(2, '0') +
      '° ' +
      min.toString().padStart(2, '0') +
      "' " +
      sec.toFixed(2).padStart(5, '0') +
      '" ' +
      dir
    );
  }
}
