import * as moment from 'moment';
import { Deserializable } from './deserializable.model';

export class TimeWindow implements Deserializable {
  from: moment.Moment;
  to: moment.Moment;
  now: moment.Moment;

  deserialize(input: any) {
    Object.assign(<any>this, input);
    return this;
  }
}
