import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'removeunderscore',
})
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const v = value + '';
    if (_.isNumber(value)) {
      return value;
    }
    if (_.isNull(value) || _.isEmpty(value)) {
      return '';
    }
    if (value.includes('_')) {
      return new TitleCasePipe().transform(v.replace(/_/g, ' '));
    } else if (value.includes('n.a')) {
      return value;
      // return new UpperCasePipe().transform(value);
    } else {
      return new TitleCasePipe().transform(value);
    }
  }
}
