export interface EnvironmentConfiguration {
  company_asset_table?: any;
  filter_layout?: any;
  kpi_status?: any;
  measures_groups?: any[];
  settings: any;
}
export enum Solutions {
  COPILOT = 'rina_copilot',
}
