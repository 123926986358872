import { Component, Input } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'cube-activable-card',
  templateUrl: './activable-card.component.html',
  styleUrls: ['./activable-card.component.scss'],
})
export class ActivableCardComponent extends MatCheckbox {
  @Input() label: any;
}
