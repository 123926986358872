import { Component, OnInit, OnDestroy } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

import { CubeMSALFlowDirective } from './directives/msal-flow.directive';
import { Theme } from './models/cube/theme.type';
import { CubeService } from './services/cube/cube.service';
import { selectTheme } from './store/selectors/system.selectors';
import { AppState } from './store/states/app.state';
import { STORAGE_KEYS } from './services/storage/storage.service';

import { Store } from '@ngrx/store';
import { fromEvent, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
@Component({
  selector: 'cube-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CubeMSALFlowDirective],
})
export class AppComponent implements OnInit, OnDestroy {
  isDarkTheme: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private overlayContainer: OverlayContainer,
    private cubeService: CubeService,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.setEventListeners();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  private setEventListeners(): void {
    // Theme switching
    this.store
      .select<Theme>(selectTheme)
      .pipe(map((theme: Theme): boolean => theme === 'dark'))
      .subscribe({
        next: (isDark: boolean) => {
          this.isDarkTheme = isDark;
          this.overlayContainer
            .getContainerElement()
            .classList.remove(isDark ? 'light-theme' : 'dark-theme');
          this.overlayContainer
            .getContainerElement()
            .classList.add(!isDark ? 'light-theme' : 'dark-theme');
        },
      });
  }
}
