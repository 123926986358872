<mat-form-field class="year-picker-container">
  <!-- Start Year -->
  <input
    readonly
    matInput
    [formControl]="yearFormControl"
    [matDatepicker]="yearPicker"
    [min]="minDate"
    [max]="maxDate"
    (click)="yearPicker.open()"
  />
  <mat-icon
    class="clear-button"
    matSuffix
    (click)="onClickClearYear()"
    *ngIf="yearFormControl?.value"
  >
    close
  </mat-icon>
  <mat-datepicker-toggle matPrefix [for]="yearPicker"></mat-datepicker-toggle>
  <mat-datepicker
    #yearPicker
    startView="multi-year"
    (yearSelected)="onYearSelected($event, yearPicker)"
  ></mat-datepicker>
</mat-form-field>
