import { NgxLoggerLevel } from 'ngx-logger';

const companyConfig: any = {
  logo: './assets/logo.png',
  logoBg: 'bgffd300',
  name: 'hr', // TODO
  role: '*',
};

const grantedMailAddresses: string[] = [];

export const globalEnvironment = {
  name: 'Copilot',
  production: false,
  company: companyConfig,
  map: {
    mapBox:
      'pk.eyJ1IjoiYWxibXUiLCJhIjoiY2p0eTN3emR5MGR1bDN5cjN3b2pvOW1pNSJ9.aB49CLoKOHwwUsnIPoFJgg',
    weather: '',
  },
  auth: {
    refreshing_time: 5000,
    token_lifespan: 60,
    bearerExcludedUrls: ['//wms.tidetech.org', '//wmts.tidetech.org'],
    grantedMailAddresses,
  },
  api: {
    seismicEvents: 'seismic/events',
    geojson: 'seismic/geojson',
  },
  logger: {
    serverLoggingUrl: 'http://localhost:3000/logs',
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.ERROR,
  },
  render_delay: 400,
  refreshing_time: '10s',
  warn_timeout: 5000,
  error_timeout: 10000,
  snackbar_duration: 1500,
  citationPageLimit: 10,
  snackbar_error_duration: 3000,
  date_format: 'MM-dd-yyyy HH:mm',
  messages: {
    error: 'common.error',
  },
  first_available_date: '2021-01-01',
  supportUrl: 'https://support.cube.rina.org',
  google: {
    analytics: {
      active: false,
      uaId: 'UA-128968550-2',
      logging: {
        debug: false,
        exceptions: true,
        verbose: false,
      },
    },
  },
  bypass_auth_token_resources: ['blob.core.windows.net', '/assets/i18n/'],
};
