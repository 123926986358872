import { Component, Inject } from '@angular/core';

import { ConfirmationDialogData } from '../../../models/confirmation-dialog-data.dto';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'cube-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  confirmationData: ConfirmationDialogData;

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    this.confirmationData = {
      ...this.matDialogData,
    };
  }

  onClickClose(): void {
    this.dialogRef.close(false);
  }

  onClickConfirm(): void {
    this.dialogRef.close({
      data: 'confirm',
    });
  }
}
