import { LanguageOption } from '../../models/cube/language-option.type';

import { createAction, props } from '@ngrx/store';

export const SystemActions = {
  ChangeTheme: createAction('[System] change theme'),
  OpenPage: createAction(
    '[System] Open Page',
    props<{ url: string; target?: string }>()
  ),
  Redirect: createAction('[System] Redirect', props<{ url: string }>()),
  RedirectToCourtesyPage: createAction('[System] Redirect To Courtesy Page'),
  RedirectToHomePage: createAction('[System] Redirect To Home Page'),
  ToggleLanguage: createAction(
    '[System] toggle language',
    props<{ language: LanguageOption }>()
  ),
  SetCurrentLang: createAction(
    '[System] set current language',
    props<{ currentLang: LanguageOption }>()
  ),
};
