import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MessageService } from '@cube/lib-common';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { ActivableCardComponent } from './activable-card/activable-card.component';
import { ActivableCardGroupDirective } from './activable-card/activable-card-group';
import { FullscreenComponent } from './fullscreen/fullscreen.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { SidemenuItemComponent } from './sidemenu-item/sidemenu-item.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { ReactiveComponentModule } from '@ngrx/component';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { LoggerModule } from 'ngx-logger';
import { MomentModule } from 'ngx-moment';
import {
  PerfectScrollbarModule,
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    ActivableCardComponent,
    ActivableCardGroupDirective,
    FullscreenComponent,
    SearchBarComponent,
    SidebarComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarComponent,
    UserMenuComponent,
  ],
  imports: [
    CommonModule,
    LoggerModule.forRoot(environment.logger),
    LetDirective,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatToolbarModule,
    MomentModule,
    // PerfectScrollbarModule,
    // ReactiveComponentModule,
    RouterModule,
    SharedModule,
    TranslateModule,
  ],
  exports: [
    ActivableCardComponent,
    ActivableCardGroupDirective,
    FullscreenComponent,
    LetDirective,
    // ReactiveComponentModule,
    RouterModule,
    SearchBarComponent,
    SidebarComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarComponent,
    UserMenuComponent,
  ],
  providers: [
    // {
    //   provide: PERFECT_SCROLLBAR_CONFIG,
    //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    // },
    MessageService,
  ],
})
export class CoreModule {}
