import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicDirective } from './dynamic-directive/dynamic.directive';
import { RoleDirective } from './role/role.directive';

const directives = [DynamicDirective, RoleDirective];

@NgModule({
  declarations: directives,
  imports: [CommonModule],
  exports: directives,
})
export class DirectivesModule {}
