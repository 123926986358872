import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { UserService } from '../../services/user/user.service';
import { UserRole } from '../../shared/configs/user-role.config';

@Directive({
  selector: '[appRole]',
})
export class RoleDirective implements OnInit {
  requiredRolesList!: UserRole[];

  @Input() set appRole(rolesList: UserRole[]) {
    this.requiredRolesList = rolesList;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.toggleTemplateVisibility();
  }

  private toggleTemplateVisibility(): void {
    if (this.userService.hasRole(this.requiredRolesList)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
