import { LanguageOption } from '../../models/cube/language-option.type';
import { Theme } from '../../models/cube/theme.type';
import { AppState } from '../states/app.state';
import { SystemState } from '../states/system.state';

import { createSelector } from '@ngrx/store';

const systemState = (state: AppState): SystemState => state.system;
export const selectCurrentLang = createSelector(
  systemState,
  (state: SystemState): LanguageOption => state.currentLang
);

export const selectTheme = createSelector(
  systemState,
  (state: SystemState): Theme => state?.theme
);
