import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import {
  Tenant,
  ProfileFeature,
  ProfileRole,
} from '../../app/models/auth/admin-tool.type';
import { environment } from '../../environments/environment';

import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly msalService: MsalService = inject(MsalService);
  private readonly http: HttpClient = inject(HttpClient);

  // Admin tool API services
  tenants(uniqueId: string): Observable<Array<Tenant>> {
    return this.http.get<Array<Tenant>>(
      `${environment.ADMIN_TOOL_URL}/users/${uniqueId}/solutions/${environment.solution}/tenants`
    );
  }

  roles(uniqueId: string, tenant: string): Observable<Array<ProfileRole>> {
    return this.http.get<Array<ProfileRole>>(
      `${environment.ADMIN_TOOL_URL}/tenants/${tenant}/users/${uniqueId}/platforms/CUBE/solutions/${environment.solution}/roles`
    );
  }

  features(role: string, tenant: string): Observable<Array<ProfileFeature>> {
    return this.http.get<Array<ProfileFeature>>(
      `${environment.ADMIN_TOOL_URL}/tenants/${tenant}/roles/${role}/platforms/CUBE/solutions/${environment.solution}/features`
    );
  }

  login() {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logoutRedirect();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();
    const allAccounts = this.msalService.instance.getAllAccounts();
    if (!activeAccount && allAccounts.length > 0) {
      this.msalService.instance.setActiveAccount(allAccounts[0]);
    }
  }
}
