<mat-nav-list
  [style.maxHeight]="menu.open && chechForChildMenu() ? '1200px' : '48px'"
  [ngClass]="{ secondaryMenu: secondaryMenu, primary: !secondaryMenu }"
>
  <mat-list-item
    *ngIf="menu.link == false"
    (click)="menu.open = !menu.open"
    [matTooltip]="menu.name | translate"
    matTooltipPosition="right"
  >
    <div class="side-list-item">
      <mat-icon matListIcon iconsmall *ngIf="menu.mat_icon"
        >{{ menu.mat_icon }}
      </mat-icon>
      <mat-icon matListIcon iconsmall *ngIf="menu.fa_icon"
        ><i class="fa fa-{{ menu.fa_icon }}"></i
      ></mat-icon>
      <h3 matLine *ngIf="!iconOnly">{{ menu.name | translate }}</h3>
      <mat-chip-list *ngIf="menu?.chip && !iconOnly">
        <mat-chip>{{ menu?.chip?.value }} </mat-chip>
      </mat-chip-list>
      <mat-icon
        *ngIf="chechForChildMenu()"
        class="sidenav-dropdown-indicator rotate"
        [ngClass]="{ indicateOpen: menu.open }"
        >expand_more
      </mat-icon>
    </div>
  </mat-list-item>

  <mat-list-item
    *ngIf="menu.link != false"
    (click)="menu.open = !menu.open"
    [routerLink]="[menu.link]"
    [fragment]="menu.fragment"
    [queryParams]="menu.params"
    [matTooltip]="menu.name | translate"
    matTooltipPosition="right"
    routerLinkActive="active"
  >
    <div class="side-list-item">
      <mat-icon matListIcon iconsmall *ngIf="menu.mat_icon"
        >{{ menu.mat_icon }}
      </mat-icon>
      <mat-icon matListIcon iconsmall *ngIf="menu.fa_icon"
        ><i class="fa fa-{{ menu.fa_icon }}"></i
      ></mat-icon>
      <h3 matLine *ngIf="!iconOnly">{{ menu.name | translate }}</h3>
      <mat-icon
        *ngIf="chechForChildMenu()"
        class="sidenav-dropdown-indicator rotate"
        [ngClass]="{ indicateOpen: menu.open }"
        >expand_more
      </mat-icon>
    </div>
  </mat-list-item>

  <cube-sidemenu-item
    *ngFor="let submenu of menu?.sub"
    [menu]="submenu"
    [iconOnly]="iconOnly"
    [secondaryMenu]="true"
  ></cube-sidemenu-item>
</mat-nav-list>
