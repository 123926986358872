export const MODULES = {
  'chat-section': {
    roles: ['ChatEngineModule'], // TODO
    route: {
      path: 'chat-section',
      loadChildren: () =>
        import('../app/pages/chat/chat.module').then((m) => m.ChatModule),
      data: {
        module: 'chat-section',
      },
    },
    menu: {
      name: 'side_menu.chat',
      mat_icon: 'chat',
      open: false,
      link: 'chat-section',
    },
  },
  'file-section': {
    roles: ['FileManagerModule'], // TODO
    route: {
      path: 'files',
      loadChildren: () =>
        import('../app/pages/files/files.module').then((m) => m.FilesModule),
      data: {
        module: 'file-section',
      },
    },
    menu: {
      name: 'side_menu.files',
      mat_icon: 'folder',
      open: false,
      link: 'files',
    },
  },
};
