<!-- Title -->
<h5 mat-dialog-title>
  {{
    confirmationData.title ?? "confirmation_dialog.confirmation"
      | translate
      | titlecase
  }}
</h5>

<mat-dialog-content>
  <div class="dialog-form">
    <p>
      {{
        confirmationData.message ?? "confirmation_dialog.are_you_sure"
          | translate
      }}
    </p>
    <p>
      {{ confirmationData.subContent ?? "" | translate }}
    </p>

    <!-- Buttons -->
    <div class="action-buttons">
      <!-- Cancel -->
      <button
        mat-flat-button
        color="warn"
        [matDialogClose]="false"
        (click)="onClickClose()"
        *ngIf="confirmationData.isCancelButtonVisible ?? true"
      >
        {{
          confirmationData.cancelButtonText ?? "common.cancel"
            | translate
            | titlecase
        }}
        <mat-icon>remove_circle_outline</mat-icon>
      </button>

      <!-- Confirm -->
      <button mat-flat-button color="primary" (click)="onClickConfirm()">
        {{
          confirmationData.confirmButtonText ?? "common.ok"
            | translate
            | titlecase
        }}
        <mat-icon>
          <span class="material-symbols-outlined">check_circle</span>
        </mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>
