<div class="sidemenu">
  <div class="side-menu-main-content" style="min-height:100%;">
    <div>
      <div class="side-menu-item">
        <cube-sidemenu-item *ngFor="let menu of menus" [menu]="menu" [iconOnly]="iconOnly"></cube-sidemenu-item>
      </div>
    </div>
    <div>
      <div fxLayout="column" fxLayoutAlign="end center" [style.margin]="'50px 0px 0px'">
        <div *ngIf="!iconOnly">
          <a href="https://www.rina.org" target="_blank" alt="RINA.org" title="RINA S.P.A.">
            <img width="75" src="./assets/rina/RINA-light.png">
          </a>
        </div>
        <div *ngIf="iconOnly">
          <a href="https://www.rina.org" target="_blank" alt="RINA.org" title="RINA S.P.A.">
            <img width="50" src="./assets/rina/RINA-light.png">
          </a>
        </div>
        <div class="version">{{version.version}}</div>
      </div>
    </div>
  </div>
</div>
