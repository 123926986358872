import { NgModule } from '@angular/core';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';

export const DATE_FORMATS = {
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  exports: [],
  declarations: [],
  providers: [
    /* {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
    }, */
    /* {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher,
    }, */
    /* {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    }, */
    /* {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS,
      // useValue: MAT_MOMENT_DATE_FORMATS,
      // useValue: {
      //   parse: {
      //     dateInput: ['l', 'LL'],
      //   },
      //   display: {
      //     dateInput: 'L',
      //     monthYearLabel: 'MMM YYYY',
      //     dateA11yLabel: 'LL',
      //     monthYearA11yLabel: 'MMMM YYYY',
      //   },
      // },
    }, */
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    /* {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        pageSize: 25,
        pageSizeOptions: [10, 25, 100],
        showFirstLastButtons: true,
      },
    }, */
  ],
})
export class MaterialModule {}
