import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Injectable } from '@angular/core';

export enum Errors {
  required = 'required',
  minLength = 'minlength',
  maxLength = 'maxlength',
  invalidName = 'invalidname',
  alreadyExists = 'alreadyexists',
  invalidNumber = 'invalidnumber',
  positiveNumber = 'positivenumber',
  invalidDecimalPlaces = 'decimalplaces',
  notANumber = 'notanumber',
  min = 'min',
  max = 'max',
  pattern = 'pattern',
  noSlash = 'noSlash',
  noDuplicates = 'noDuplicates',
}

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  readonly emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;

  public getMessage(
    errorName: string,
    errorValue: any,
    fieldName: string
  ): [string, Record<string, any> | null] {
    const minmax =
      fieldName === 't' || fieldName === 'threshold'
        ? `, ${fieldName} Min must be less than ${fieldName} Max`
        : ``;
    const translationKey = 'validation';

    const errorMessages = {
      [Errors.alreadyExists]: [`${translationKey}.alreadyExists`],
      [Errors.invalidDecimalPlaces]: [
        `${translationKey}.invalidDecimalPlaces`,
        { required: errorValue.required },
      ],
      [Errors.invalidName]: [`${translationKey}.invalidName`],
      [Errors.invalidNumber]: [`${translationKey}.invalidNumber`],
      [Errors.maxLength]: [
        `${translationKey}.maxLength`,
        { requiredLength: errorValue.requiredLength },
      ],
      [Errors.notANumber]: [`${translationKey}.notANumber`],
      [Errors.pattern]: [`${translationKey}.pattern`, { fieldName }],
      [Errors.positiveNumber]: [`${translationKey}.positiveNumber`],
      [Errors.required]: [`${translationKey}.required`],
      [Errors.noSlash]: [`${translationKey}.noSlash`, { field: fieldName }],
      [Errors.noDuplicates]: [
        `${translationKey}.noDuplicates`,
        { field: fieldName },
      ],
      // [Errors.min]: `Field must be between ${ValidatorConst[fieldName + 'Min']} and
      //   ${ValidatorConst[fieldName + 'Max']}`,
      // [Errors.max]: `Field must be between ${ValidatorConst[fieldName + 'Min']} and
      //   ${ValidatorConst[fieldName + 'Max']}`,
    };

    return errorMessages[errorName];
  }

  public duplicateValidator(key: string, formArray: FormArray): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const controlValues = formArray?.controls.map(
        (group: any) => group.get(key)?.value
      );

      return controlValues?.filter((value) => value === control.value).length >
        1
        ? { noDuplicates: true }
        : null;
    };
  }

  public getDecimalRegex(decimals: number): RegExp {
    return new RegExp(`^-?\\d+(\\.\\d{1,${decimals}})?$`);
  }

  public maxFileSizeValidator(maxSizeMB: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const files: File[] = control.value || [];
      const totalSizeMB =
        files.reduce((acc, file) => acc + file.size, 0) / (1024 * 1024);

      return totalSizeMB > maxSizeMB ? { maxFileSizeExceeded: true } : null;
    };
  }

  public noSlashValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const regEx = /^[^\/]*$/;
      if (value && !regEx.test(value)) {
        return { noSlash: true };
      }
      return null;
    };
  }

  public regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value == null) return null;

      return regex.test(control.value) ? null : error;
    };
  }
}
