import { SystemActions } from '../actions/system.actions';
import { SystemState } from '../states/system.state';

import { createReducer, on } from '@ngrx/store';

const INIT_STATE: SystemState = {
  currentLang: 'it',
  theme: 'light',
};

export const systemReducers = createReducer(
  INIT_STATE,
  on(
    SystemActions.SetCurrentLang,
    (state: SystemState, { currentLang }): SystemState => ({
      ...state,
      currentLang,
    })
  ),

  on(
    SystemActions.ToggleLanguage,
    (state: SystemState): SystemState => ({
      ...state,
      currentLang: state.currentLang === 'en' ? 'it' : 'en',
    })
  ),

  on(
    SystemActions.ChangeTheme,
    (state: SystemState): SystemState => ({
      ...state,
      theme: state.theme === 'dark' ? 'light' : 'dark',
    })
  )
);
