import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly _storage = localStorage;

  constructor() {}

  addToStorage(key: string, value: any): void {
    this._storage.setItem(key, JSON.stringify(value));
  }

  getFromStorage(key: string): any {
    return JSON.parse(this._storage.getItem(key));
  }

  deleteFromStorage(key: string): any {
    this._storage.removeItem(key);
  }
}

export const STORAGE_KEYS = {
  token: 'token',
  version: 'version',
  sub: 'sub',
  localTimeFormat: 'local_time_format',
  excludedMap: 'excluded-map',
  activeRole: 'active-role',
  mapBaseLayer: 'map-base-layer',
  currentTenant: 'current-tenant',
  language: 'language',
};
