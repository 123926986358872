import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';

import { SpinnerService } from '../spinner/spinner.service';
import { SKIP_SPINNER_INTERCEPTOR } from '../token/public-api.token';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.context.get(SKIP_SPINNER_INTERCEPTOR)) {
      this.spinnerService.requestStarted();
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!request.context.get(SKIP_SPINNER_INTERCEPTOR)) {
          this.spinnerService.requestEnded();
        }
      })
    );
  }
}
